<template>
  <div>
    <v-container fluid class="mt-lg-10">{{sampleData}}
      <v-card>
        <v-app-bar dark color="primary">
          <v-toolbar-title>{{ $t('msg.download_resources') }}</v-toolbar-title>
        </v-app-bar>
        <v-container  fluid>
          <v-row>
            <v-col cols="12" md="12" lg="12" class="text-center">
              <v-skeleton-loader
                  :loading="loading" transition="scale-transition" type="table-row">
                <v-data-table :headers="headers"
                              :footer-props="{
                                  'items-per-page-options': pagination.itemPerPageOptions,
                                  'show-current-page':true
                              }"
                              :items="itemsWithIndex">
                  <template v-slot:item.download="{ item }">
                    <span v-if="item.fileAvailable">
                      <v-icon color="green" @click.native="downloadFile(item)">mdi-file-download</v-icon>
                    </span>
                    <span v-else>
                      <v-icon>mdi-file-download</v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {dataServices, downloadFileService} from "../../../services";
import fileSaver from 'file-saver'
export default {
  name: "LearningResources",
  data() {
    return {
      sampleData:'',
      tableData: [],
      loading: true,
      pagination: {
        itemsPerPage: 10,
        itemPerPageOptions: [10]
      },
      headers: [
        {
          text: this.$t('msg.sl_no'), align: 'center', sortable: false, value: 'index', selectedCheckbox: true,
          toggle: false
        },
        {
          text: this.$t('msg.resource_name'), align: 'center', sortable: false, value: 'resourceName', selectedCheckbox: true,
          toggle: false
        },
        {
          text: this.$t('msg.download'), align: 'center', sortable: false, value: 'download', selectedCheckbox: true,
          toggle: false
        },
      ]
    }
  },
  methods: {
    getPageData: function () {
      dataServices.getLearningResources().then(response => {
        if (response.dataResponse.learningResourcesData != null){
          this.tableData = response.dataResponse.learningResourcesData
        }
        this.loading = false
      })
    },
    downloadFile : function (item) {
      downloadFileService.downloadResource({id: item.id}).then(response => {
        if (response) {
          fileSaver.saveAs(response,'resource'+item.extension)
        }
      })
    }
  },
  computed: {
    itemsWithIndex: function () {
      return this.tableData.map(
          (item, index) => ({
            ...item,
            index: index + 1
          }))
    }
  },
  mounted() {
    this.getPageData();
  }
}
</script>

<style scoped>

</style>
